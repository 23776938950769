@use 'sass:map';
@use '../../../variables' as vars;

@mixin fnl-mdc-checkbox($fnl-palette) {

  .fnl-mdc-checkbox {
    .mdc-form-field {
      max-height: 20px;
    }
    .mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
      display: none; 
    }
    
    .mdc-checkbox .mdc-checkbox__native-control:focus-visible ~ .mdc-checkbox__ripple {
      display: block; 
    }
    .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
    .mdc-checkbox .mdc-checkbox__native-control:enabled:focus:focus:not(:checked):not(:indeterminate)~.mdc-checkbox__background,
    .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
      border-color: #a8bccb;
    }

    .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
      color: vars.$color-white;
    }
  }

}
